var pomlo_app = {};

// Define constants
pomlo_app.MOBILE_MAX_WIDTH = 768;
pomlo_app.TABLET_MAX_WIDTH = 992;
pomlo_app.DEFAULT_DURATION = 300;
pomlo_app.SMALL_HEADER_HEIGHT = 60;
pomlo_app.LARGE_HEADER_HEIGHT = 88;

/**
* Function to scroll to a specific html element
*/
pomlo_app.scrollTo = function(selector) {
    var headerHeight = (jQuery(window).width() < pomlo_app.TABLET_MAX_WIDTH) ? pomlo_app.SMALL_HEADER_HEIGHT :  pomlo_app.LARGE_HEADER_HEIGHT;
    // console.log("header: " + headerHeight);
    // console.log(selector + " offset: " + jQuery(selector).offset().top);
    // console.log(selector + " position: " + jQuery(selector).position().top);
    // jQuery("#main-content").animate({
    //     scrollTop: (jQuery(selector).offset().top - headerHeight)
    // }, 600);
    //jQuery("#main-content " + selector).smoothScroll();

    // Rely on jQuery plugin as native scrollTop & offset is not reliable
    jQuery.smoothScroll({
        offset: -(headerHeight - 1),
        scrollElement: $("#main-content"),
        scrollTarget: selector
    });
};

pomlo_app.heroFilter = function() {
    if (jQuery(window).width() > pomlo_app.MOBILE_MAX_WIDTH) {
        $( ".slide" ).find('#filter').remove();
    } else if (($( ".slide" ).find('#filter')).length === 0){
        $( ".slide" ).append('<div id="filter" class="filter filter-white-60"></div>');
    }
};

/**
* Main jQuery events
*/
jQuery(document).ready(function($) {
    pomlo_app.heroFilter();

    // Setup intersection observers to trigger actions
    // pomlo_app.observeKeywordsSection();

    // Init OWL hero slider
    $("#homeHeroSlider").owlCarousel({
        items: 1,
        loop: true,
        autoplay: true,
        autoplayTimeout: 7000,
        nav: false,
        dots: false,
        onInitialized: function(event){
            if (event.item.count === 1) {
                jQuery("#heroSliderPrev").hide();
                jQuery("#heroSliderNext").hide();
            }
        }
    });



    owlHeroSlider = jQuery("#homeHeroSlider").owlCarousel();
    jQuery("#heroSliderPrev").click(function () {
        owlHeroSlider.trigger("prev.owl.carousel");
    });

    jQuery("#heroSliderNext").click(function () {
        owlHeroSlider.trigger("next.owl.carousel");
    });

    // Init OWL hero slider for the new carrousel
    $("#homeHeroSlider-2").owlCarousel({
        items: 1,
        loop: true,
        autoplay: true,
        autoplayTimeout: 8000,
        nav: false,
        dots: false,
        onInitialized: function(event){
            if (event.item.count === 1) {
                jQuery("#heroSliderPrev-2").hide();
                jQuery("#heroSliderNext-2").hide();
            }
        }
    });

    var owlHeroSlider2 = jQuery("#homeHeroSlider-2").owlCarousel();
    jQuery("#heroSliderPrev-2").click(function () {
        owlHeroSlider2.trigger("prev.owl.carousel");
    });

    jQuery("#heroSliderNext-2").click(function () {
        owlHeroSlider2.trigger("next.owl.carousel");
    });

    jQuery("#homeWhoSlider").owlCarousel({
        items: 1,
        loop: true,
        autoplay: true,
        autoplayHoverPause: true
    });

    // Menu button
    $("#btn-menu-open, #btn-menu-close").click(function() {
        $(".offcanvas").toggleClass("active");
    });

    $(".hide-menu").click(function() {
        $(".offcanvas").removeClass("active");
    });

    // Removes some anchor links managed by js click/animatescroll.
    // And manage scroll buttons
    $(".scroll-link").removeAttr("href");

    $(".scroll-to-top").click(function() {
        pomlo_app.scrollTo("#page-top");
    });

    $(".scroll-to").click(function(){
        pomlo_app.scrollTo($(this).attr("data-target"));
    });

    $("#form-contact").submit(function(e) {
        e.preventDefault();
        if(!$('#private-policy').is(':checked')){
            $("#privacy-policy-message").text("Envoi impossible, veuillez lire et accepter la politique de confidentialité");
            return false;
        }

        var fields = {
            name: $("#name").val(),
            company: $("#company").val(),
            telephone : $("#telephone").val(),
            email: $("#email").val(),
            message: $("#message").val(),
        };

        var data = {
            apiKey: "ec2ad17c-ca08-5fc6-809e-baf59e124582",
            to: "contact@voxcivis.fr",
            fields: fields,
        };

        $.ajax({
            url: "https://api1.pomlo.fr/email/send",
            type: "POST",
            dataType: "json",
            contentType: "application/json",
            data: JSON.stringify(data),
            success: function (result) {
                console.log(result);
                $("#form-message").html("Votre message vient d'être envoyé avec succes, nous vous contacterons dans les plus brefs délais.");
            },
            error: function (xhr, ajaxOptions, thrownError) {
                console.log(thrownError);
                $("#form-message").html("Votre message n'a pas pu être envoyé, réessayez plus tard.");
            }
        });

    });

});

jQuery("#main-content").scroll(function() {
});

jQuery(window).resize(function() {
    pomlo_app.heroFilter();
});
